.spinner-root {
  background-color: white;
  width: 100%;
  height: 100%;
}
.spinner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  z-index: 30;
}
.spinner-logo {
  width: 70px;
}
