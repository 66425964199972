.pop-up {
  background-color: var(--clr-txt-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 20;
  opacity: 50%;
}
