.box-shadow-form {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.aus-phone-confirm-form {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 480px;
    min-width: 380px;
}

.phone-ring {
    animation: ring 4s 0.7s ease-in-out infinite;
    transform-origin: 50% 50%;
}

@keyframes ring {
    0% {
        transform: rotate(0);
    }
    5% {
        transform: rotate(30deg);
    }
    10% {
        transform: rotate(-28deg);
    }
    15% {
        transform: rotate(34deg);
    }
    20% {
        transform: rotate(-32deg);
    }
    25% {
        transform: rotate(30deg);
    }
    30% {
        transform: rotate(-28deg);
    }
    35% {
        transform: rotate(26deg);
    }
    40% {
        transform: rotate(-24deg);
    }
    45% {
        transform: rotate(22deg);
    }
    50% {
        transform: rotate(-20deg);
    }
    55% {
        transform: rotate(18deg);
    }
    60% {
        transform: rotate(-16deg);
    }
    65% {
        transform: rotate(14deg);
    }
    70% {
        transform: rotate(-12deg);
    }
    75% {
        transform: rotate(10deg);
    }
    80% {
        transform: rotate(-8deg);
    }
    85% {
        transform: rotate(6deg);
    }
    90% {
        transform: rotate(-4deg);
    }
    95% {
        transform: rotate(2deg);
    }
    100% {
        transform: rotate(-1deg);
    }
}
