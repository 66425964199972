.nav-root {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}
.nav-ctn-1 {
  display: flex;
}

.nav-ctn-2 {
  display: flex;
  gap: 20px;
}

.nav-txt {
  font-weight: 600;
  color: var(--clr-txt-primary);
  font-size: var(--font-size-header);
}

.nav-logo-frame {
}
.nav-txt-btn {
  font-weight: 600;
  size: 16px;
  margin-right: 10px;
}
.nav-link {
  text-decoration: none;
}
