.log-service {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.log-lock-icon {
  display: inline-block;
  transform: translateY(-1px);
  font-weight: 800;
  font-size: 19px;
  color: var(--clr-primary);
}
.log-txt-forgot {
  font-weight: 600;
  margin-left: 5px;
  color: var(--clr-primary);
}
.log-accept {
  user-select: d;
}
.log-reject {
  user-select: none;
}
.log-link-forgot {
  color: var(--clr-primary);
  text-decoration: none;
}
.log-link-forgot:hover {
  color: var(--clr-primary);
}
