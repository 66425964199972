.p-stop-icon-form {
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  background-color: var(--clr-txt-error);
  border-radius: 50%;
  z-index: 2;
  position: relative;
}
.p-stop-icon-form::before,
.p-stop-icon-form::after {
  content: " ";
  position: absolute;
  border-radius: 50%;
  height: 100%;
  width: 100%;
  background-color: var(--clr-txt-error);
  z-index: -1;
  opacity: 0.7;
}
.p-stop-icon-form::before {
  animation: animate 2s ease-out infinite;
}
.p-stop-icon-form::after {
  animation: stop 2s 1s ease-out infinite;
}
@keyframes stop {
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
.threedots-stop > div {
  justify-content: center;
}
