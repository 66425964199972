.nav-auth-form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 25px;
}
.nav-form {
}
.nav-auth-icon-notify {
    font-size: 25px;
    color: #4e4e4e;
    cursor: pointer;
}
.nav-avatar {
    display: flex;
    background: none;
    border: none;
    padding: 0;
    width: 40px;
    box-shadow: none;
    align-items: center;
    gap: 10px;
    width: fit-content;
    cursor: pointer;
    user-select: none;
}
.nav-avatar-img {
    margin: 0;
    padding: 0;
    border-radius: 50%;
    display: inline-block;
}
.nav-auth {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.nav-menu {
    border-radius: none;
    position: absolute;
    background-color: white;
    width: fit-content;
    display: flex;
    flex-direction: column;
    transform: translateY(26px);
    border: 1px solid rgb(233, 233, 233);
}

.nav-menu-topic {
    display: flex;
    justify-content: start;
    align-items: center;
    color: var(--clr-txt-secondary);
    gap: 10px;
    padding: 10px 20px;
    cursor: pointer;
}

.nav-menu-topic a {
    text-decoration: none;
    color: var(--clr-txt-secondary);
}

.nav-menu-topic:hover,
.nav-menu-topic:hover a {
    color: var(--clr-light);
    background-color: var(--clr-primary);
}

.nav-menu-topic a {
    white-space: nowrap;
}
.nav-menu-topic-icon {
    font-size: 16px;
}
.avatar-form {
    margin-right: 20px;
}
.user-name {
    display: flex;
    margin: 0;
}
.txt-name {
    display: inline-block;
    color: #6666;
    margin: 0;
    padding: 0 10px;
}
.nav-logo-frame {
    padding-left: 20px;
}
.notify-form {
    position: absolute;
    background-color: white;
    padding: 20px;
    max-width: 300px;
    right: 0;
    transform: translate(-100px, 34px);
}
.notify-form-content {
    width: 100%;
}
.notify-header {
    font-size: 18px;
    font-weight: 700;
    user-select: none;
}
.notify-title {
    font-size: 16px;
    margin-bottom: 5px;
    font-weight: 600;
}
