.ban-background {
  display: flex;
  animation: sparkle 60s linear infinite;
  position: relative;
  padding: 120px 0;
}

.ban-form-content {
  width: 100%;
}
.ban-content-2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ban-content-2 img {
  width: 100%;
}

.ban-ctn-1 {
  margin-right: 60px;
}
.ban-img-frame {
  display: inline-block;
}
.ban-img {
  width: 100%;
  display: inline-block;
}
.ban-content {
  margin-right: 0;
  max-width: 500px;
  padding-left: 100px;

  display: inline-block;
  position: relative;
}
@media only screen and (max-width: 768px) {
  .ban-img {
    display: none;
  }
}
@media only screen and (max-width: 560px) {
  .ban-content {
    padding-left: 0;
  }
}
/* .ban-img{
    transition-duration: 0.6s, 0.6s, 0.6s, 0.3s, 0.6s, 0.6s, 0.3s;
    transition-timing-function: ease, ease, ease, ease, ease, ease, ease;
    transition-delay: 0s, 0s, 0s, 0s, 0s, 0s, 0s;
    transition-property: all;
} */
/* .ban-img:hover{
    transform: scale(1.02);
} */

.ban-txt-header {
  font-size: 3.125em;
  font-weight: 800;
  font-family: var(--font-family);
  line-height: 60.95px;
  color: white;
  text-align: left;
}

.ban-txt {
  color: white;
  text-align: left;
  font-weight: 500;
}

.ban-background {
  background-color: var(--clr-primary);
  /* background-image: url(	https://australianstormcourier.com.au/wp-content/themes/flatsome3.15/assets/img/effects/sparkle1.png)
    url(https://australianstormcourier.com.au/wp-content/themes/flatsome3.15/assets/img/effects/sparkle2.png); */
}

.del-img {
  max-height: 496px;
  max-width: 648px;
  object-fit: cover;
  border-radius: 4px;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1), 0 6px 10px 0 rgba(0, 0, 0, 0.1);
}

.del-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.del-root {
  background-color: #f3f3f3;
  padding: 40px 0;
}

.del-row {
  max-width: 1362.5px;
}

.rate-root {
  padding: 40px 0;
}

.mar-item {
  display: flex;
  gap: 40px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 1px 3px -2px;
}
.mar-img {
  display: inline-block;
}
.mar-content {
  display: inline-block;
}
.mar-item-frame {
  display: flex;
}

.mar-txt-header {
  text-align: center;
  font-weight: 700;
}

.mar-txt {
  text-align: center;
}

.cus-img {
  max-width: 435px;
}

.cus-root {
  background-color: #f3f3f3;
}
.btn-cus {
  color: white;
  font-weight: 600;
  background-color: var(--clr-primary);
  border: none;
  width: fit-content;
  font-size: 0.85em;
  width: 100%;
  max-width: 370px;
  font-family: var(--font-family);
  font-weight: 600;
  border-radius: 8px;
  padding: 10px 25px;
}
.cus-item {
  box-shadow: 0 1px 3px -2px;
  background-color: white;
  transition-duration: 0.6s, 0.6s, 0.6s, 0.3s, 0.6s, 0.6s, 0.3s;
  transition-timing-function: ease, ease, ease, ease, ease, ease, ease;
  transition-delay: 0s, 0s, 0s, 0s, 0s, 0s, 0s;
  transition-property: all;
  text-align: center;
  border-radius: 4px;
}
.cus-item:hover {
  transform: translateY(-15px);
}
.as-img {
  max-width: 172px;
  display: inline-block;
}
.as-ctn-1 {
  text-align: center;
}

@keyframes sparkle {
  0% {
    background-position: 0px 0px, 0px 0px, 0px 0px;
  }

  100% {
    background-position: -500px -1000px, -400px -400px, 300px 300px;
  }
}

.card-header {
  font-weight: 600;
  margin-bottom: 15px;
}
