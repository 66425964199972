.p-success-root {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  height: 70vh;
  background-color: white;
}
.p-txt-grey {
  color: var(--clr-txt-secondary);
}
.p-success-icon {
  font-size: 70px;
  color: white;
}
.p-success-icon-form {
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  background-color: var(--clr-txt-success);
  border-radius: 50%;
  z-index: 2;
  position: relative;
}
.p-success-icon-form::before,
.p-success-icon-form::after {
  content: " ";
  position: absolute;
  border-radius: 50%;
  height: 100%;
  width: 100%;
  background-color: var(--clr-txt-success);
  z-index: -1;
  opacity: 0.7;
}
.p-success-icon-form::before {
  animation: animate 2s ease-out infinite;
}
.p-success-icon-form::after {
  animation: animate 2s 1s ease-out infinite;
}
@keyframes animate {
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
