.review-form {
    border: 1px solid var(--clr-input);
    margin: 15px 0;
    padding: 10px;
    border-radius: 5px;
    max-width: 500px;
}
.review-content {
    margin: 10px 0;
    word-wrap: break-word;
    width: 100%;
}
