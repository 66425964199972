.ft-content {
  text-align: center;
  color: var(--clr-txt-primary);
  margin: 0;
  padding: 15px;
}
.ft-root {
  background-color: #fff4e5;
  width: 100%;
}
.ft-root-cus {
  background-color: #fff4e5;
  width: 100%;
  position: fixed;
  bottom: 0;
}
