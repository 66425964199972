.pickup-post {
    display: grid;
    grid-template-columns: auto auto;
    gap: 25px;
}
.pickup-post input {
    min-width: 170px !important;
}
.form-img-rate {
    display: grid;
    grid-template-columns: auto auto;
}

.swiper-slide img {
    display: block;
    width: 100%;
    object-fit: cover;
}

.swiper-pagination-bullet {
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    color: #000;
    user-select: none;
    opacity: 1;
    background: rgba(0, 0, 0, 0.2);
}

.swiper-pagination-bullet {
    color: var(--clr-primary);
    background: none;
    /* padding: 20px; */
    user-select: none;
    font-size: 14px;
    cursor: pointer;
}
.swiper-pagination-bullet-active {
    color: var(--clr-txt-primary);
    font-size: 14px;
}
.swiper-pagination {
    margin: 20px 0;
}
@media only screen and (max-width: 768px) {
    .pickup-post {
        grid-template-columns: auto;
    }
}
@media only screen and (max-width: 1094px) {
    .form-img-rate {
        grid-template-columns: auto;
    }
}
.item-root {
    max-width: 1400px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    flex-grow: 1;
    position: relative;
}
.times-createProduct-form {
    font-size: 25px;
    width: 100%;
    text-align: right;
}
.times-createProduct {
    display: inline-block;
    cursor: pointer;
    color: var(--clr-txt-primary);
}
.list-item {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}
.t-invoice-root {
    width: 100%;
    font-family: var(--font-family);
}
.t-invoice-form {
    margin: 0 auto;
    height: fit-content;
}
.t-invoice-header-txt {
    font-weight: 600;
    font-size: 20px;
}
.t-invoice-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
    border-bottom: 2px solid var(--clr-txt-primary);
}
.t-invoice-form-suburb {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    margin: 10px 0;
}
.t-invoice-to {
    display: flex;
    gap: 30px;
    padding-top: 15px;
    padding-bottom: 5px;
    border-bottom: 2px solid var(--clr-txt-primary);
}
.t-invoice-to-child {
    font-weight: 600;
}
.t-invoice-item {
    padding-top: 5px;
}
.t-invoice-to-content {
    flex-grow: 1;
}

.aus-dropdown-menu {
    padding: 0;
    max-height: 0;
    visibility: hidden;
    opacity: 0;
    transition: max-height 200ms, opacity 200ms;
}

.aus-dropdown-menu[data-show='true'] {
    max-height: 400px;
    opacity: 1;
    visibility: visible;
    transition: max-height 200ms, opacity 200ms;
}

.aus-dropdown-item {
    list-style-type: none;
    cursor: pointer;
    border-bottom: 1px solid #ccc;
    padding: 5px;
}

#orderItems .swiper-button-next,
#orderItems .swiper-button-prev {
    left: -10px;
}

.swiper-nav-btns {
    position: absolute;
    top: 50%;
    z-index: 1;
    font-size: 3.5rem;
    color: var(--clr-txt-primary);
}

.swiper-nav-prev {
    left: 0;
}

.swiper-nav-next {
    right: 0;
    text-align: end;
}
