:root {
    --clr-primary: #f2a13b;
    --clr-secondary: #ffffff;
    --clr-txt-primary: rgba(17, 17, 17, 0.85);
    --clr-light: #fff4e5;
    --clr-txt-secondary: #666666;
    --clr-bgr-home: #f3f3f3;
    --clr-input: #fafafa;
    --clr-primary-light: #fff4e5;
    --clr-mes-success: #eef5ea;
    --clr-btn-gray: #eaeaea;
    --clr-txt-success: #559b2d;
    --clr-content-success: #eef5ea;
    --clr-txt-error: #c50000;
    --clr-mes-error: #fae6e6;
    --clr-content-blue: #cceffc;
    --clr-txt-blue: #00afef;
    --clr-process: #dedede;
    --font-size: 15px;
    --font-family: 'Roboto', sans-serif;
}

.root {
    min-height: 90vh;
}

* {
    box-sizing: border-box;
    scroll-behavior: smooth;
}

html {
    font-size: var(--font-size);
}

body {
    font-family: var(--font-family);
    /* overflow-y: hidden; */
}
/* #root {
    overflow-y: hidden;
} */

.text-primary {
    color: var(--clr-primary) !important;
}

.container-define {
    min-height: calc(100vh - 96px - 54px);
}
.img-auto-flex {
    width: 100%;
    height: 100%;
}
.btn-edit {
    color: White !important;
}
.my-btn-yellow {
    color: var(--clr-txt-primary) !important;
    font-weight: 600 !important;
    background-color: var(--clr-primary) !important;
    border: none !important;
    width: fit-content !important;
    font-size: 0.85em !important;
    font-family: var(--font-family) !important;
    border-radius: 30px !important;
    padding: 10px 25px !important;
}
.my-btn-yellow :active {
    color: var(--clr-txt-primary) !important;
    background-color: var(--clr-primary) !important;
    border: none !important;
}
.my-btn-yellow:hover {
    color: var(--clr-txt-primary) !important;
    background-color: var(--clr-primary) !important;
    border: none !important;
}
.my-btn-yellow {
    color: white !important;
    font-weight: 600 !important;
    background-color: var(--clr-primary) !important;
    border: none !important;
    width: fit-content !important;
    font-size: 0.85em !important;
    font-family: var(--font-family) !important;
    border-radius: 30px !important;
    padding: 10px 25px !important;
}

.my-btn-yellow :active {
    color: white !important;
    background-color: var(--clr-primary) !important;
    border: none !important;
}

.my-btn-yellow:hover {
    color: white !important;
    background-color: var(--clr-primary) !important;
    border: none !important;
}
.my-btn-gray {
    color: var(--clr-txt-primary) !important;
    font-weight: 600 !important;
    background-color: var(--clr-btn-gray) !important;
    border: none !important;
    width: fit-content !important;
    font-size: 0.85em !important;
    font-family: var(--font-family) !important;
    border-radius: 30px !important;
    padding: 10px 25px !important;
}
.my-btn-gray :active {
    color: var(--clr-txt-primary) !important;
    background-color: var(--clr-btn-gray) !important;
    border: none !important;
}
.my-btn-gray:hover {
    color: var(--clr-txt-primary) !important;
    background-color: var(--clr-btn-gray) !important;
    border: none !important;
}
.my-btn-red {
    color: white !important;
    font-weight: 600 !important;
    background-color: var(--clr-txt-error) !important;
    border: none !important;
    width: fit-content !important;
    font-size: 0.85em !important;
    font-family: var(--font-family) !important;
    border-radius: 30px !important;
    padding: 10px 25px !important;
}
.my-btn-red :active {
    color: white !important;
    background-color: var(--clr-txt-error) !important;
    border: none !important;
}
.my-btn-red:hover {
    color: white !important;
    background-color: var(--clr-txt-error) !important;
    border: none !important;
}
.my-btn-green {
    color: white !important;
    font-weight: 600 !important;
    background-color: var(--clr-txt-success) !important;
    border: none !important;
    width: fit-content !important;
    font-size: 0.85em !important;
    font-family: var(--font-family) !important;
    border-radius: 30px !important;
    padding: 10px 25px !important;
}
.my-btn-green :active {
    color: white !important;
    background-color: var(--clr-txt-success) !important;
    border: none !important;
}
.my-btn-green:hover {
    color: white !important;
    background-color: var(--clr-txt-success) !important;
    border: none !important;
}
.txt-center {
    text-align: center;
}
::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #6c757d !important;
}

::placeholder {
    /* Most modern browsers support this now. */
    color: #6c757d !important;
}
.label {
    font-weight: 600;
    font-size: 1.1rem;
    display: inline;
}
.asterisk {
    color: red;
    display: inline;
    font-weight: 1000;
    padding-left: 3px;
}
.eyes-pass {
    position: absolute;
    cursor: pointer;
    right: 0;
    top: 0;
    font-size: 20px;
    z-index: 3;
    transform: translate(-45%, 5%);
}
.frame-pass {
    position: relative;
}
@keyframes sparkle {
    0% {
        background-position: 0px 0px, 0px 0px, 0px 0px;
    }

    100% {
        background-position: -500px -1000px, -400px -400px, 300px 300px;
    }
}

@-webkit-keyframes fadeLeft {
    from {
        opacity: 0;
        -webkit-transform: translateX(-50px);
        transform: translateX(-50px);
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}
@keyframes fadeLeft {
    from {
        opacity: 0;
        -webkit-transform: translateX(-50px);
        transform: translateX(-50px);
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}
.fadeLeft {
    -webkit-animation: fadeLeft 0.8s;
    animation: fadeLeft 0.8s;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
}
.fadeLeft-slower {
    -webkit-animation: fadeLeft 1.3s 0.8s;
    animation: fadeLeft 1.3s 0.8s;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
}

@-webkit-keyframes fadeRight {
    from {
        opacity: 0;
        -webkit-transform: translateX(50px);
        transform: translateX(50px);
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}
@keyframes fadeRight {
    from {
        opacity: 0;
        -webkit-transform: translateX(50px);
        transform: translateX(50px);
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}
.fadeRight {
    -webkit-animation: fadeRight 0.8s;
    animation: fadeRight 0.8s;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
}

@-webkit-keyframes fadeDown {
    from {
        opacity: 0;
        -webkit-transform: translateY(-50px);
        transform: translateY(-50px);
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}
@keyframes fadeDown {
    from {
        opacity: 0;
        -webkit-transform: translateY(-50px);
        transform: translateY(-50px);
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}
.fadeDown {
    -webkit-animation: fadeDown 0.8s;
    animation: fadeDown 0.8s;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
}
textarea {
    background-color: var(--clr-input);
    padding: 8px 12px;
}
input[type='checkbox']:checked {
    background-color: #f2a13b;
}
input[type='checkbox']:checked::before {
    background-color: #f2a13b;
}
input[type='checkbox']:checked::after {
    background-color: #f2a13b;
}
input[type='checkbox']:active {
    background-color: #f2a13b;
}
input[type='text'] {
    background-color: var(--clr-input);
    padding: 8px 12px;
    border: 1px solid var(--clr-txt-secondary);
}
input[type='number'] {
    background-color: var(--clr-input);
    padding: 8px 12px;
    border: 1px solid var(--clr-txt-secondary);
}
input[type='password'] {
    background-color: var(--clr-input);
    padding: 8px 12px;
    border: 1px solid var(--clr-txt-secondary);
}
input[type='checkbox'] {
    cursor: pointer;
}
input {
    min-width: 200px !important;
}
.aus-drop-down {
    background-color: var(--clr-input);
    padding: 8px 12px;
    border: 1px solid var(--clr-txt-secondary);
}
span.checkmark {
    cursor: pointer;
}

select {
    cursor: pointer;
}

.fr-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.fr-checkbox {
    display: block;
    position: relative;
}
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: 2px solid #666666;
    border-radius: 3px;
}
input:checked ~ .checkmark {
    background-color: var(--clr-primary);
    border: 2px solid var(--clr-primary);
}
.checkmark:after {
    content: '';
    position: absolute;
    display: none;
}
.container input:checked ~ .checkmark:after {
    display: block;
}
.fr-checkbox .checkmark:after {
    display: block;
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid var(--clr-light);
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    color: white;
}
.txt-checkbox {
    margin-left: 30px;
    font-weight: 600;
}
.override-block {
    width: 20px;
    height: 20px;
    background-color: white;
    z-index: 2;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 20px;
    transform: translate(-45%, 40%);
}
.form {
    padding: 20px;
    max-width: 700px;
    margin: 0 auto;
}
.form-group {
    margin-bottom: 15px;
}
.dr-btn {
    background-color: var(--clr-input) !important;
    color: var(--clr-txt-primary) !important;
    border: 1px solid #dddddd !important;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    padding: 8px 12px !important;
}
.dr-btn:hover {
    background-color: var(--clr-btn-light);
    color: var(--clr-txt-primary);
    border: 1px solid #dddddd;
}
.reg-dr {
    background-color: var(--clr-input);
}
.img-front-frame {
    max-height: 300px;
    max-width: 350px;
    border-radius: 15px;
    padding: 20px 0;
    cursor: pointer;
    object-fit: cover;
    margin-bottom: 20px;
    position: relative;
}
.img-front {
    width: 100%;
    height: 200px;
    border-radius: 15px;
}
.rate-pop-up > div {
    width: fit-content;
    padding: 20px;
}
.rate-pop-up > div > div {
    padding: 10px;
}
a {
    cursor: pointer;
}
.content-green {
    background-color: var(--clr-content-success);
    color: var(--clr-txt-success);
    padding: 0px 10px;
    font-weight: 600;
    border-radius: 20px;
    width: fit-content;
}
.content-yellow {
    background-color: var(--clr-primary-light);
    color: var(--clr-primary);
    padding: 0px 10px;
    font-weight: 600;
    border-radius: 20px;
    width: fit-content;
}
.content-red {
    background-color: var(--clr-mes-error);
    color: var(--clr-txt-error);
    padding: 0px 10px;
    font-weight: 600;
    border-radius: 20px;
    width: fit-content;
}
.content-blue {
    background-color: var(--clr-content-blue);
    color: var(--clr-txt-blue);
    padding: 0px 10px;
    font-weight: 600;
    border-radius: 20px;
    width: fit-content;
}
.txt-success {
    color: var(--clr-txt-success);
    font-weight: 600;
}
.txt-error {
    color: var(--clr-txt-error);
    font-weight: 600;
}
.link-slider > .active {
    border-left: none !important;
}
.form-group-border {
    border-radius: 0.8rem;
    position: relative;
    border: 1px solid var(--clr-txt-secondary);
    padding: 1.2rem 0.5rem 0.5rem;
    z-index: 2;
}
.form-group-border * {
    z-index: 2;
}
.form-group-border .form-group-label {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(10px, -50%);
    background-color: #ffffff;
    display: inline-block;
    width: fit-content;
    padding: 0 0.2rem;
    z-index: 1;
    margin: 0;
}
